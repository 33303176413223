<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />
          <h2 class="brand-text text-primary ml-1">
            More information
          </h2>
        </b-link>

        <b-card-title class="mb-1" />
        <b-card-text class="mb-2" />

        <!-- form -->
        <b-form
          class="auth-register-form mt-2"
          @submit.prevent="updateUsers"
        >

          <!-- userId -->
          <b-form-group
            label="ID"
            label-for="userId"
          >
            <b-form-input
              id="userId"
              v-model="registData.userid"
              name="register-userId"
              disabled
            />
          </b-form-group>

          <!-- username -->
          <b-form-group
            label="Your Name"
            label-for="userName"
          >
            <b-form-input
              id="userName"
              v-model="registData.username"
              name="register-userName"
            />
          </b-form-group>

          <!-- mobile -->
          <b-form-group
            label="Mobile"
            label-for="mobile"
          >
            <b-form-input
              id="mobile"
              v-model="registData.mobile"
              name="register-mobile"
            />
          </b-form-group>

          <!-- accountBank -->
          <b-form-group
            label="Account Bank"
            label-for="accountBank"
          >
            <b-form-input
              id="accountBank"
              v-model="registData.accountBank"
              name="register-accountBank"
            />
          </b-form-group>

          <!-- accountNumber -->
          <b-form-group
            label="Account Number"
            label-for="accountNumber"
          >
            <b-form-input
              id="accountNumber"
              v-model="registData.accountNumber"
              name="register-accountNumber"
            />
          </b-form-group>

          <!-- outcomePassword -->
          <b-form-group
            label="Outcome Password"
            label-for="outcomePassword"
          >
            <b-input-group
              class="input-group-merge"
            >
              <b-form-input
                id="outcomePassword"
                v-model="registData.outcomePassword"
                :type="passwordFieldType"
                class="form-control-merge"
                name="register-outcomePassword"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <!-- submit button -->
          <b-button
            variant="primary"
            block
            type="submit"
          >
            Regist
          </b-button>
        </b-form>
      </b-card>
    </div>
  </div>

</template>

<script>
import {
  BCard, BLink, BCardTitle, BCardText, BForm,
  BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Swal from 'sweetalert2'

import { createNamespacedHelpers } from 'vuex'
import { UPDATE_USERS_ADDITIONAL } from '@/store/auth/mutation'

const authStore = createNamespacedHelpers('authStore')

export default {
  components: {
    VuexyLogo,
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      registData: {
        userid: null,
        username: null,
        mobile: null,
        accountBank: null,
        accountNumber: null,
        outcomePassword: null,
      },
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    // this.registData.userid = this.$store.getters['authStore/user'].userid
    this.registData.userid = JSON.parse(localStorage.getItem('userData')).userid
    this.registData.site = JSON.parse(localStorage.getItem('userData')).site
    this.registData.status = 'await'
  },
  methods: {
    ...authStore.mapActions({
      $updateUsersAdditional: UPDATE_USERS_ADDITIONAL,
    }),

    updateUsers() {
      // this.$refs.registerForm.validate()
      //   .then(success => {
      //     if (success) {
      this.$updateUsersAdditional(this.registData)
        .then(response => {
          if (response !== undefined) {
            Swal.fire({
              title: '추가정보를 제출하였습니다. \n 최종승인 후 접속 가능합니다.',
              // text: "로그인 창으로 이동합니다.",
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              // cancelButtonColor: '#d33',
              confirmButtonText: '확인',
              background: 'rgb(3,11,37)',
              customClass: {
                confirmButton: 'btn btn-primary mr-1',
              },
            }).then(result => {
              if (result.isConfirmed) {
                this.$router.push({ name: 'login' })
              }
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failure',
                icon: 'EditIcon',
                variant: 'error',
              },
            })
          }
        })
      // }
    // })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
